import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/pet-play/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const PetPlay = ({ data, path }) => {

    return (
        <Project
            title="Pet PLAY"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                P.L.A.Y. (Pet Lifestyle and You) creates stylish, eco-friendly pet products, 
                including beds, toys, and accessories. They focus on combining functionality
                with modern design, using sustainable materials like recycled plastic bottle 
                fillings. Their products cater to pets’ comfort and owners’ aesthetics, 
                with a commitment to quality and community initiatives, such as donating 
                beds to shelters.
                </p>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('pp')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('pp')} />
                </div>
                <FeatureCreator projectData={projects.get('pp')} />
             </Content>
        </Project>
    )
}


PetPlay.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default PetPlay
