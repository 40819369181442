import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/aptos-retail/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const AptosRetail = ({ data, path }) => {

    return (
        <Project
            title="Aptos Retail"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                Aptos is a leading provider of cloud-based retail solutions, 
                offering tools for point of sale, order management, merchandising, 
                and analytics. Their unified commerce platform helps retailers streamline 
                operations, improve customer experiences, and adapt to evolving market needs. 
                Trusted by brands like New Balance and The North Face, Aptos focuses on enhancing 
                loyalty and maximizing revenue for retailers.
                </p>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('ar')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('ar')} />
                </div>
                <FeatureCreator projectData={projects.get('ar')} />
             </Content>
        </Project>
    )
}


AptosRetail.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default AptosRetail
