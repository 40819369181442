import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/landyield/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const Landyield = ({ data, path }) => {

    return (
        <Project
            title="LandYield"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                LandYield is a platform that helps forest landowners, 
                particularly those with smaller properties (between 40 to 5,000 acres),
                participate in the carbon market. Traditionally, carbon credits were mainly 
                accessible to large landowners due to high costs and complex requirements. 
                LandYield changes this by offering a streamlined, technology-driven process 
                that allows smaller landowners to defer commercial timber harvests and get 
                paid for the carbon their forests sequester.
                </p>
                <p>
                The platform uses advanced satellite data and tools to monitor forest carbon 
                and enables landowners to receive quarterly payments by participating in 
                carbon sequestration programs. The goal is to make it easier for family 
                forest owners to generate revenue while preserving the environmental and 
                recreational value of their lands .
                </p>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('ly')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('ly')} />
                </div>
                <FeatureCreator projectData={projects.get('ly')} />
             </Content>
        </Project>
    )
}


Landyield.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default Landyield
